import React, {useContext, useEffect, useState} from 'react';
import './style.scss'
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormattedMessage} from "react-intl";
import {useHistory, useParams} from "react-router-dom";
import {AssetsContext} from "../../context/assetsContext";
import LangButton from "../langButton";

const Navbar = ({props}) => {
        const {language,handleLoading} = useContext(AssetsContext)
        const history = useHistory()
        const [page, setPage] = useState('')
        const [scroll, setScroll] = useState(0)
        const {lang} = useParams()
        const [toggleOpen, setToggleOpen] = useState('close-nav')
        const navItemClick = (nav) => {
            handleLoading(true)
            if (language == undefined) {
                history.push(`${props.match.url}`)
            } else {
                setToggleOpen('close-nav')
                history.push(`${props.match.url}/${language}/${nav}`)
                setPage(`/${nav}`)
            }
        }
        useEffect(() => {
            let i = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
            setPage(`/${i}`)

            // return()=>window.removeEventListener('scroll');
        }, [])
        return (

            <>
                <div className={`header ${toggleOpen}`}
                     style={{direction: ['ar', 'kr'].includes(language) ? 'rtl' : 'ltr'}}>
                    <div className="container">
                        <div className="nav">
                            <div className={'nav-logo'}>
                                <img src={require('../../images/logo-png.png')} alt="logo"/>
                            </div>
                            <div className="menu-toggle">
                                <i className="fas fa-bars" onClick={() => setToggleOpen('open')}><FontAwesomeIcon
                                    icon={faBars}/></i>
                                <i className="fas fa-times" onClick={() => setToggleOpen('close-nav')}><FontAwesomeIcon
                                    icon={faTimes}/></i>
                            </div>
                            <ul className="nav-list">
                                <li className="nav-item" onClick={() => {
                                    navItemClick('')
                                }}>
                                    <a
                                        className={`nav-link ${page && ['/','/app'].includes(page) === true  ? 'active' : ''}`}><FormattedMessage
                                        id={'home'}/>
                                    </a>
                                </li>
                                {/*<li className="nav-item" onClick={() => {*/}
                                {/*    navItemClick('gallery')*/}
                                {/*}}>*/}
                                {/*    <a*/}
                                {/*        className={`nav-link ${page && page === '/gallery' ? 'active' : ''}`}><FormattedMessage*/}
                                {/*        id={'gallery'}/></a>*/}
                                {/*</li>*/}
                                <li className="nav-item" onClick={() => {
                                    navItemClick('Projects')
                                }}>
                                    <a
                                        className={`nav-link ${page && page === '/Projects' ? 'active' : ''}`}><FormattedMessage
                                        id={'projects'}/></a>
                                </li>
                                <li className="nav-item" onClick={() => navItemClick('contactus')}>
                                    <a
                                        className={`nav-link ${page && page === '/contactus' ? 'active' : ''}`}>
                                        <FormattedMessage id={'contactUS'}/>
                                    </a>
                                </li>
                                {/*<li className="nav-item" onClick={() => navItemClick('ceoMessage')}>*/}
                                {/*    <a*/}
                                {/*        className={`nav-link ${page && page === '/ceoMessage' ? 'active' : ''}`}>*/}
                                {/*        <FormattedMessage id={'CEO Message'}/>*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
    }

;

export default Navbar;
