import React, {useContext, useEffect} from 'react';
import './style.scss'
import {AssetsContext} from "../../context/assetsContext";

const SitePlan = (props) => {
    const {handleLoading}=useContext(AssetsContext)

    useEffect(() => {
        const onPageLoad = () => {
            handleLoading(false);
        };

        // Check if the page has already loaded
        if (document.readyState === "complete") {
            onPageLoad();
        } else {
            window.addEventListener("load", onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener("load", onPageLoad);
        }
    }, []);

    return(
        <div className={'sitePlan-container'}>
            <img src={require('../../images/foum.jpg')} alt="oum.jpg"/>

        </div>
    )
    }

;

export default SitePlan;
