// import React from 'react';
// import './style.scss'
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faMapPin} from "@fortawesome/free-solid-svg-icons";
// import {faFacebook, faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";
// import ContactDetails from "../contentDetails";
// import Navbar from "../navbar";
// import {FormattedMessage} from "react-intl";
//
// const Footer = (props) => {
//         return (
//             <div>
//                 <div className={'footer-container'}>
//                     <div className={'seconde-line'}>
//                         <div className="content">
//                             <div className="content-details">
//                                 {/*<span>0751 100 1005</span>*/}
//                                 {/*<span>0772 221 1004</span>*/}
//                                 <span>Copyright © 2022, Golden Mountains Company</span>
//                                 {/*<span>Investment & Constructions!</span>*/}
//                             </div>
//                         </div>
//
//                     </div>
//                 </div>
//
//             </div>
//         )
//     }
//
// ;
//
// export default Footer;
import React, {useEffect} from 'react';
import './style.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapPin} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";
import ContactDetails from "../contentDetails";
import Navbar from "../navbar";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

const Footer = (props) => {

        return (
            <div className={'footer-container'} id={'footer'}>
                <div className={'first-line'}>
                    <div className={'footer-details'}>
                        <div className="title">About Us</div>
                        <div className="describtion">
                            <img src={require('../../images/logo-png.png')} alt="logo"/>
                        </div>
                        <div className="tail">
                            {/*<FormattedMessage id={'hello'}/>*/}
                            <p className={'describtion'}>
                                <FormattedMessage id={'aboutUsFooter'}/>
                            </p>
                        </div>
                        {/*<div className="icons">*/}
                        {/*    <a href={'https://www.facebook.com/GoldenTowersErbil'} target={'_blank'}>*/}
                        {/*        <FontAwesomeIcon icon={faFacebook} size={"lg"}/>*/}
                        {/*    </a>*/}
                        {/*    <Link to={''}>*/}
                        {/*        <FontAwesomeIcon icon={faYoutube} size={"lg"}/>*/}
                        {/*    </Link>*/}
                        {/*    <Link to={''}>*/}
                        {/*        <FontAwesomeIcon icon={faInstagram} size={"lg"}/>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}

                    </div>
                    <div className={'footer-details'}>
                        <div className="title">working Hours</div>
                        <div className="describtion">
                            <FormattedMessage id={'workingHours'}/>
                        </div>
                        {/*<div className="tail">*/}
                        {/*    Lorem ipsum dolor sit amet, consectetur adipisicing elit. At consequatur dicta harum*/}
                        {/*    laboriosam nesciunt nostrum perspiciatis quos recusandae, reprehenderit voluptatibus!*/}
                        {/*</div>*/}
                    </div>
                    <div className={'footer-details'}>
                        <div className="title">Why Choose Us</div>
                        {/*<div className="multi-images">*/}
                        {/*    /!*<img className={'gt'} src={require('../../images/gt.png')} alt=""/>*!/*/}
                        {/*    <img className={'gt'} src={require('../../images/Layer 3.png')} alt=""/>*/}
                        {/*    <img className={'gt'} src={require('../../images/Layer 2.png')} alt=""/>*/}
                        {/*</div>*/}
                        <div className="tail">
                            <FormattedMessage id={'whyChooseusFooter'}/>

                        </div>
                    </div>
                </div>
                <div className={'seconde-line'}>
                    <div className="content">
                        <div className="content-details">
                            <span>Copyright © 2022, Golden Mountains Company</span>
                            <span>Investment & Constructions!</span>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

;

export default Footer;
