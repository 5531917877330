import React, {Fragment, useContext, useEffect} from "react";
import {IntlProvider} from "react-intl";
import {LAN} from '../i18n/locales'
import messages from '../messages'
import {AssetsContext} from "../context/assetsContext"
import {useParams} from "react-router-dom";

const LanguagesProvider = (props) => {
    const {language}=useContext(AssetsContext)

    const {lang} = useParams();
    //  const language='en'
    return (
        <IntlProvider
            locale={language}
            textComponent={Fragment}
            messages={messages[language]}
        >
            {props.children}

        </IntlProvider>

    )
}

export default LanguagesProvider;
