import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import App from './App'
import AssetsProvider, {AssetsContext} from "./context/assetsContext";


const MainApp = ({match}) => {
    return (
        <AssetsProvider>

            <div className="page-container">
                <div className="content-wrap">
                    <Router>
                        <Switch>
                            <Redirect exact from={`/`} to={`/app`}/>
                            <Route path={'/app'} component={App}/>
                        </Switch>
                    </Router>
                </div>
            </div>


        </AssetsProvider>

    )
}

export default MainApp;
