import React, {useState, useEffect, useContext} from 'react';
import './style.scss'
import LangButton from '../../component/langButton'
import {Spinner} from 'react-bootstrap'
import {AssetsContext} from "../../context/assetsContext";
import Counter from "../../component/counter";
import BuildingProgress from "../../component/buildingProgress";
import {Button} from "@material-ui/core";
import Projects from "../../component/projects";
import WhatWeDo from "../../component/whatdo";
import {FormattedMessage} from "react-intl";
import Hero from "../../component/hero";
import Landing from './landing'
import OurHistory from "../../component/ourHistory";

const LandingPage = (props) => {
        // const [loading,setLoading]=useState(true)
        const {loading, handleLoading, language} = useContext(AssetsContext)

        useEffect(() => {
            const onPageLoad = () => {
                handleLoading(false);
            };

            // Check if the page has already loaded
            if (document.readyState === "complete") {
                onPageLoad();
            } else {
                window.addEventListener("load", onPageLoad);
                // Remove the event listener when component unmounts
            }
            return () => window.removeEventListener("load", onPageLoad);
        }, []);
        return (
            <>

                <Landing/>
                {/*<WhatWeDo/>*/}
                {/*<OurHistory/>*/}
                <Projects/>
            </>
        )
    }

;

export default LandingPage;
