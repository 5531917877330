// import Hero from "./component/hero";
// import FloorPlan from "./component/floorPlan";
// import TypePlan from './component/typePlan'
// import Qr from "./component/qr";
// import PaymentsPlans from "./component/paymentsPlans";
// import SingleProject from "./component/singleProject";
// import Home from "./pages/home";
// import ProjectsPage from "./pages/projectsPage";
// import Daftara from "./component/daftara";
// import ContactUsSideCo from "./component/contactUsSideComponent";
// import SalesPerson from "./component/salesPerson";

import React, {useEffect, useContext, useState,lazy,Suspense} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch, useParams} from 'react-router-dom';
import './miniCss.scss'
import './assets/animation.scss'
import Header from "./component/Header";
import './assets/animation.scss'
import Footer from "./component/footer";
import {LanProvider} from "./i18n";
import LanguagesProvider from "./context/Languages";
import AssetsProvider, {AssetsContext} from "./context/assetsContext";
import LandingPage from "./pages/landingPage";
import {setClick} from "./assets/ApiFunctions";
import Projects from "./component/projects";
import SitePlan from "./component/sitePlan";
import assetsContext from "./context/assetsContext";
import Moment from 'moment'
import Error from '../src/component/error'
import {Spinner} from "react-bootstrap";


const CeoMessage = lazy(()=>import("./component/ceoMessage"))
const ProjectsPage = lazy(()=>import("./pages/projectsPage"))
const PaymentsPlans = lazy(()=>import("./component/paymentsPlans"))
const TypePlan = lazy(()=>import("./component/typePlan"))
const TypePayment = lazy(()=>import("./component/typePayment"))
const ContactUs = lazy(()=>import("./pages/contactUs"))

function App({match, history}) {

    const {loading} = useContext(AssetsContext)
// useEffect(()=>{
//     alert(navigator.userAgent)
// },[])
    return (
        <>
            {loading && <div className={'loading'}><Spinner animation="grow"/></div>}
            <>
                <LanguagesProvider>
                    <Suspense fallback={<div className={'loading'}><Spinner animation="grow"/></div>}>
                    <Router>
                        <Header match={match} history={history}/>
                        <Switch>
                            <Route exact path={`${match.url}/`} component={LandingPage}/>
                            <Route exact path={`${match.url}/:lang`} component={LandingPage}/>
                            <Route exact path={`${match.url}/:lang/types`} component={TypePayment}/>
                            <Route exact path={`${match.url}/:lang/ceoMessage`} component={CeoMessage}/>
                            <Route exact path={`${match.url}/:lang/Projects`} component={ProjectsPage}/>
                            <Route exact path={`${match.url}/:lang/contactus`} component={ContactUs}/>
                            <Route exact path={`${match.url}/:lang/types/typeplan/:id`} component={TypePlan}/>
                            <Route exact path={`${match.url}/:lang/sitePlan`} component={SitePlan}/>
                            <Route exact path={`${match.url}/:lang/paymentsPlans/:id`} component={PaymentsPlans}/>
                            {/*<Route exact path={`${match.url}/:lang/qr`} component={Qr}/>*/}
                            <Route exact path={`${match.url}/error`} component={Error}/>
                            <Route component={Error}/>
                        </Switch>
                    </Router>

                    <Footer/>
                    </Suspense>
                </LanguagesProvider>
            </>
        </>


    );
}

export default App;
