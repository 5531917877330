import React, {useContext, useEffect, useRef, useState} from 'react';
import './style.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faMapPin} from '@fortawesome/free-solid-svg-icons'
import ContactDetails from '../../component/contentDetails'
import {faFacebook, faYoutube, faInstagram} from '@fortawesome/free-brands-svg-icons'
import Navbar from "../navbar";
import {useParams} from "react-router-dom";
import {AssetsContext} from "../../context/assetsContext";
import LangButton from "../langButton";


const Header = (props) => {

    const forward=(loc)=>{
        window.open(loc,'_self')
    }
    const {language} = useContext(AssetsContext)
    const [display,setDisplay]=useState(true)

    const height=useRef()
    // useEffect(()=>{
    //     const {offsetTop} = height.current
    //     window.addEventListener('scroll',()=>{
    //         if(height.current != null){
    //             if(window.pageYOffset >= offsetTop){
    //                 height.current.classList.add('fixed-header')
    //                 setDisplay(false)
    //             }else{
    //                 height.current.classList.remove('fixed-header')
    //                 setDisplay(true)
    //             }
    //
    //         }
    //     })
    //     // return () => window.removeEventListener('scroll');
    // },[])
        return (
            <>
            <div className={'header-container'} style={{direction: ['ar', 'kr'].includes(language) ? 'rtl' : 'ltr'}}>
                <div className={'first-line'} style={{display:display && 'none !important'}}>
                    <div className="company">
                        <div className="company-logo">
                            <img src={require('../../images/logo-png.png')} alt="golden mountains apartment"/>
                        </div>
                    </div>
                    {/*<div className="location">*/}
                    {/*    <FontAwesomeIcon icon={faMapPin} size={"lg"}/>*/}
                    {/*    <span>Erbil - Italian Village 1, Villa 248</span>*/}
                    {/*</div>*/}
                    <div className="icons" >
                        <LangButton props={props} display={'flex-row'}/>
                    </div>
                </div>
            </div>
        <div className="third-line fixed-header" ref={height}>
            <Navbar props={props}/>
        </div>
</>
    )
    }

;

export default Header;
