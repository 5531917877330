import en from "./en";
import ar from "./ar";
import kr from "./kr";



export default {
    ...en,
    ...kr,
    ...ar
}
