import {LAN} from '../i18n/locales'

export default {
    [LAN.KURDISH]: {
        welcome: 'بەخێربێن',
        roomNumber: 'ژمارەی ژۆر',
        readMore: 'بۆ بینینی زیاتر',
        newpayment: 'شێوازی پارەدانی نوێ بۆ سالی ۲٠۲۲',
        availabeAppartment: 'شوقەی بەردەست لە گۆلدن تاوەرز',
        Salon: 'هۆل',
        suiteSpace: 'سويت',
        bedRoom1: 'ژووری نوستن',
        bedRoom2: 'ژووری نوستنی مندال',
        Kitchen: 'مەتبەخ',
        twoilet: 'توالیت',
        Store: 'مەخزەن',
        bathRoomSpace: 'حمام',
        balcone: 'بالکۆن',
        halls: 'مەمەر',
        downloadPdf: 'داگرتنی کەتەلۆک',
        plansDesriprion: 'کۆمپانیای گۆلدن ماونتنز بۆ وەبەرھێنان سیستەمی جیاوازی پارەدانی گونجاوتان پێشەکەشدەکات، وە ھەر سیستەمێک تاییبەتمەندی خۆی ھەیە.'
        , checkPaymentsPlans: 'بۆ بینینی سیستەمی پارەدانی ئەم شوقەیە',
        clickToCall: 'بۆ قسەکردن لەگەڵ سەنتەری فرۆشتن',
        clickHere: 'کلیک لێرە بکە',
        paymentsPlan: 'سیستەمی پارەدانی تاییبەت بە',
        goldenPlusD: 'نرخی گشتی شوقەکە دەدرێت لەکاتی واژۆ کردنی گرێبەست',
        goldenPlus: 'سیستەمی گۆڵدن پڵەس',
        golden: 'سیستەمی گولدن ',
        goldenD: 'یەک دەفعە دەدرێت لەکاتی واژۆکردنی گرێبەست بەبڕی ١٥٪‏ لە نرخی شوقەکە ئەوەی دەمێنێتەوە ھەموو سێ مانگ جارێک دەدرێت',
        silver: ' سیستەمی سلڤەر',
        silverDetails: ' سسیستەمەکە چوار دەفعەیە و ئەوەی دەمێنێتەوە قستی مانگانەیە دووای وەرگرتنی شوقەکە',
        pronzD: 'سیستەمەکە ھەمووی سێ دەفعەیە بەبێ قستی مانگانە',
        pronz: 'سیستەمی بڕۆنز',
        totalQty:'ژمارەی ئەو شوقانەی بەردەستن',
        saledQty:'ژمارەی ئەو شوقانەی فرۆشراون',
        totalSpace:'ڕووبەری شوقە',
        roomQty:'ژمارەی ژوورەکان',
        chooseCurrency:'تکایە دراوێک دیاری بکە',
        dollar:'بە دۆلار ',
        dinar:'بە دینار',
        towerProject:'گۆڵدن ماونتنز تاوەرز',
        prestigProject:'بريستيج تاورز',
        locationDesc:'ئەم پرۆژەیە دەکەوێتە شاری ھەولێر لە نزیک شەقامی ١٥٠ مەتری و پشت باڵیۆزخانەی ئەمریکا، تەنیشت گوندی کۆری. ',
        location:'شوێنی پرۆژە',
        projectDescTitle:'دەربارەی پرۆژە',
        projectDesc:'پرۆژەیەکی نیشتەجێبوونە و پێکدێت لە ١٢ باڵەخانە بە جیاوازی بەرزی بەڵەخانەکان، بە نرخی گونجاو و سیستەمی قەرزی گونجاو بۆ ھەمووان ',
        buildingQty:'ژمارەی باڵەخانەکان',
        apartmentQty:'ژمارەی شوقەکان',
        goToSitePlan:'لعرض المخطط اضغط هنا',
        comingSoon:"بەم زووانە!",
        locationClickHere:'بۆ بینینی شوێنی پرۆژە کلیک لێرە بکە ',
        underConstruction:'لەژێر چاککردن',
        chooseFlatSize:' ئەو شوقەیە هەڵبژێرە کە بۆ تۆ گونجاودەبێت',
        all:'هەموو',
        propertyFeatures:"تایبەتمەندی عەقاڕەكە",
        callUs:'اتصل بنا',
        home:'سه‌ره‌کی',
        gallery:'الصور',
        desc:"وەسف:",
        contactUS:"په‌یوه‌ندیمان پێوه‌ بکه",
        propertyFeatures:"ميزات العقار",
        address:"ناونیشان",
        contactDetails:"زانیاری په‌یوه‌ندی كردن",
        mobile:"مۆبایل",
        email:"ئیمه‌یل",
        openingHours:'كاتی كاركردن',
        openingHoursDays:"شەمە - پێنچ شەمە",
        openingHoursHm:"10:00 ص - 6:00 م",
        dayOff:" پشووی هەفتەیە",
        friday:"هەینی",
        startingPrice:'نرخ دەست پێدەکات لە',
        company:'کۆمپانیا',
        position:"پیشە",
        empl:"کارمەندی فرۆشتن",
        languages:"زمانەکان",
        compName:"گۆڵدن ماونتنز",
        projects:"پڕۆژەکان",
        project:"پڕۆژەی",
        totalFlats:"ژمارەی شوقەکان",
        buildingFloors:"ژمارەی نهۆمەکان",
        totalBuilding:"ژمارەی باڵەخانەکان",
        avalibaleIn:"بەردەستە لە",
        companyAddress:"عیراق-هەولێر, گوندی ئیتاڵی یەک ڤێلای ژمارە 248",
        parkingLots:"ژمارەی پارکینگ",

    }

}
