import {LAN} from '../i18n/locales'
export default {
    [LAN.ENGLISH]:{
        readMore:'Read More',
        newpayment:'New Payment Method 2022',
        availabeAppartment:'Golden Mountains Towers Available Apartments',
        Salon:'Hall',
        suiteSpace:'Suite',
        bedRoom1:'bedroom',
        bedRoom2:'Children\'s bedroom',
        Kitchen:'Kitchen',
        twoilet:'Toilet',
        Store:'Store',
        bathRoomSpace:'Bathroom',
        balcone:'Balcone',
        halls:'Corridors',
        downloadPdf:'Download Catalogue',
        goldenPlus:'Golden Plus',
        plansDesriprion:
            'Golden Mountains For Investment Company offers you different payment plans to suit you, and each payment plan has its own advantages'
        ,paymentsPlan:'Payments plans',
        silver:'Silver',
        silverDetails:'40% of the apartment price will divided into 4 payments and 60% of the apartment price will be monthly installments starting form receiving the apartment\'s key date',
        pronz:'Bronze',
        pronzD:'it is three payments plan method without installments',
        checkPaymentsPlans:'To check payments plans for this appartment',
        clickToCall:'To call salse office',
        clickHere:'Click Here',
        roomQty:'rooms Quantity',
        totalSpace:'apartment space',
        saledQty:'apartments sold quantity',
        totalQty:'Total apartments quantity',
        goldenD:'Only 15% of the apartment\'s price will paid with signing the contract and 85% will divided into installments every three month',
        goldenPlusD:'The total price of the apartment will paid on cash by signing the contract',
        chooseCurrency:'Kindly choose the currency',
        dollar:'Dollar',
        dinar:'Dinar',
        towerProject:'Golden Mountains Towers',
        locationDesc:' The project is located in Erbil city on 150m road,behind american Consulate and next to korean village ',
        location:'Location',
        projectDescTitle:'About Project',
        projectDesc:'Golden Mountains Towers is a residential project consisting of 12 residential Tower block , different heights ',
        buildingQty:'Blocks',
        apartmentQty:'Flats',
        locationClickHere:'View on map',
        underConstruction:'Under Construction',
        prestigProject:'Prestige Towers',
        comingSoon:"Coming Soon",
        chooseFlatSize:'Choose the flat type you interested',
        all:'All',
        startingPrice:'Strating from',
        callUs:'call us',
        contactUS:'Contact Us',
        home:'Home',
        gallery:'Gallery',
        desc:"description:",
        parking:"Parking lots",
        propertyFeatures:"Property Features",
        address:"Address",
        companyAddress:"Erbil - Italian Village 1, Villa 248",
        contactDetails:"Contact Details",
        mobile:"Mobile",
        email:"Email",
        openingHours:'Opening Hours',
        openingHoursDays:"Sat-Thu",
        openingHoursHm:"10:00 AM - 6:00 PM",
        dayOff:"Weekend",
        friday:"Friday",
        company:'Company',
        position:"Position",
        empl:"Sales",
        languages:"Languages",
        compName:"Golden Mountains",
        forInvestment:"For Investment",
        projects:"Projects",
        project:"Project",
        totalFlats:"Total Flats",
        buildingFloors:"Building Floors",
        totalBuilding:"Total Building",
        parkingLots:"Parking Lots",
        avalibaleIn:"Available In",
        constructions:'CONSTRUCTIONS',
        interiorDesigning:'INTERIOR DESIGNING',
        ivestment:'IVESTMENT',
        exteriorDesign:'EXTERIOR DESIGN',
        whatWeDoDesc:'Golden Mountains Company Properties has been shaping the erbil luxury real estate market since 2009, delivering iconic residential, commercial and leisure properties across the region. Golden Mountains Company is now working on two projects in Erbil, The first is Golden M Towers and Golden Line The company\'s main goal is to provide a large number of residential apartments at reasonable prices and convenient installments. Which allows people with limited income to own housing units easily',
        whatWeDoTitle:'WHAT WE DO',
        ourProjects:'OUR PROJECTS',
        workingHours:'  We work 7 days a week, every day excluding major holidays. Contact\n' +
            '                            us if you have an emergency, with our Hotline and Contact form.',
        aboutUsFooter:'                where we specialize in delivering high-quality construction\n' +
            '                                services to clients across various industries. Our company was founded on the principles of\n' +
            '                                integrity, innovation, and excellence, and we have been consistently delivering outstanding\n' +
            '                                results to our clients since our inception',

        whyChooseusFooter:'                            We have years of experience in the construction industry and have worked on a variety of\n' +
            '                            projects. Our experienced team knows how to get the job done efficiently and effectively.\n' +
            '                            We specialize in various areas of construction and have a team of experts who are skilled in\n' +
            '                            their respective fields. Whether it\'s commercial or residential construction, we have the\n' +
            '                            expertise to meet your needs'




    }
}
