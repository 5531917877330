import React, {useContext, useEffect} from 'react';
import './style.scss'
import {AssetsContext} from "../../context/assetsContext";
import {useHistory, Redirect, Link} from "react-router-dom";
import {setClick} from "../../assets/ApiFunctions";
import {InputLabel,NativeSelect} from '@material-ui/core'

const LangButton = ({props, display}) => {
        const {match} = props
        let history = useHistory()

        const {setUserLanguage,language} = useContext(AssetsContext)
        const languagesDetails = [
            {
                shortcut: "kr", image: "Flag_of_Kurdistan2.svg", name: "كوردي"

            },
            {
                shortcut: "ar", image: "iraq.svg", name: "عربي"

            },
            {
                shortcut: "en", image: "tobias-Flag-of-the-United-Kingdom.svg", name: "English"

            }]


        return (
            <div className={'langButton-container'}>
                <div className={'langButton-wrraper'}>
                    <div className={`langButton-content --i ${display}`}>
                        {
                            languagesDetails.filter(e=>e.shortcut != language).map((l, index) =>
                                <div className={'langButton'}>
                                    <div>
                                        <img src={require(`../../images/${l.image}`)} alt={l.image}/>
                                    </div>
                                    <div>
                                    <span onClick={() => {
                                        setClick(l.shortcut)
                                        setUserLanguage(l.shortcut);
                                        history.push(`${match.url}`)
                                    }}>{l.name}</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

        )
    }

;

export default LangButton;


