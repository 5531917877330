import React, {useContext, useEffect} from 'react';
import './style.scss'
import {AssetsContext} from "../../context/assetsContext";

const Error = (props) => {

    const {setUserLanguage,handleLoading} = useContext(AssetsContext)
    useEffect(() => {
        const onPageLoad = () => {
            handleLoading(false);
        };

        // Check if the page has already loaded
        if (document.readyState === "complete") {
            onPageLoad();
        } else {
            window.addEventListener("load", onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener("load", onPageLoad);
        }
    }, []);

    return(
        <div className="container">
            <h1 className="first-four">4</h1>
            <div className="cog-wheel1">
                <div className="cog1">
                    <div className="top"></div>
                    <div className="down"></div>
                    <div className="left-top"></div>
                    <div className="left-down"></div>
                    <div className="right-top"></div>
                    <div className="right-down"></div>
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
            </div>
            <div className="cog-wheel2">
                <div className="cog2">
                    <div className="top"></div>
                    <div className="down"></div>
                    <div className="left-top"></div>
                    <div className="left-down"></div>
                    <div className="right-top"></div>
                    <div className="right-down"></div>
                    <div className="left"></div>
                    <div className="right"></div>
                </div>
            </div>
            <h1 className="second-four">4</h1>
            <p className="wrong-para">Oh! Page not found!</p>
        </div>
    )
    }

;

export default Error;
