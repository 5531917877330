import {LAN} from '../i18n/locales'

export default {
    [LAN.ARABIC]: {
        welcome: 'اهلا وسهلا',
        readMore: 'اقرا المزيد',
        newpayment: 'نظام دفعات جديد 2022',
        availabeAppartment: 'الشقق المتوفرة في مشروع جولدن ماونتنز تاورز',
        Salon: 'صالة',
        suiteSpace: 'سويت',
        bedRoom1: 'غرفة نوم',
        bedRoom2: 'غرفة نوم اطفال',
        Kitchen: 'المطبخ',
        twoilet: 'حمام 2',
        Store: 'المخزن',
        bathRoomSpace: 'الحمام',
        balcone: 'البلكونة',
        halls: 'الممرات',
        downloadPdf: 'تحميل الكتالوك',
        goldenPlusD: 'يدفع المبلغ الاجمالي للشقة كاملا عند توقيع العقد',
        goldenPlus: 'نظام گولدن بلس',
        plansDesriprion: 'تعرض لكم شركة گولدن ماونتنز للاستثمار انظمة دفع مختلفة بما يناسبكم وكل نظام دفع يمتاز بمميزاته الخاصة  '
        , paymentsPlan: 'انظمة الدفع الخاصة ب',
        silver: ' نظام سيلفر',
        goldenD: 'هو نظام الدفعة الواحدة والتي تتم عند توقيع العقد وبعدها اقساط كل ثلاثة اشهر',
        silverDetails: '  هو نظام ل 4 دفعات , الدفعة الثانية تدفع بعد 6 أشهر من الدفعة الاولى و الدفعة الثالثة تدفع بعد 8 اشهر من الدفعة الثانية, وتتحول الى اقساط شهرية بعد الاستلام',
        pronzD: 'نظام الثلاث دفعات وبدون اقساط شهرية ',
        pronz: 'نظام برونز',
        golden: 'نظام گولدن ',
        checkPaymentsPlans: 'للتحقق من انظمة الدفع المتعلقة بهذه الشقة',
        clickHere: 'اضغط هنا',
        locationClickHere: 'لعرض الموقع اضغط هنا',
        clickToCall: 'للتحدث الى مركز المبيعات',
        totalQty: 'عدد الشقق المتوافرة',
        saledQty: 'عدد الشقق المباعة',
        totalSpace: 'مساحة الشقة',
        roomQty: 'عدد الغرف',
        chooseCurrency: 'يرجى اختيار العملة',
        dollar: 'دولار امريكي ',
        dinar: 'دينار عراقي',
        towerProject: 'گولدن ماونتنز تاورز',
        prestigProject: 'بريستيج تاورز',
        locationDesc: ' يقع المشروع في مدينة اربيل بالقرب من طريق 150 متري خلف القنصلية الامريكية وبجانب القرية الكورية ',
        location: 'الموقع',
        projectDescTitle: 'عن المشروع',
        projectDesc: 'مشروع سكني يتكون من 12 عمارة سكنية بارتفاعات مختلفة,باسعار مناسبة وبانظمة اقساط تناسب الجميع ',
        buildingQty: 'عدد الابنية',
        apartmentQty: 'عدد الشقق',
        goToSitePlan: 'لعرض المخطط اضغط هنا',
        comingSoon: "قريبا!",
        underConstruction: 'قيد الإنجاز',
        chooseFlatSize: 'اختر نوع الشقة التي تناسبك',
        all: 'الكل',
        startingPrice: 'يبدأ من',
        callUs: 'اتصل بنا',
        home: 'الرئيسية',
        gallery: 'الصور',
        desc: "الوصف:",
        contactUS: "اتصل بنا",
        propertyFeatures: "ميزات العقار",
        address: "العنوان",
        contactDetails: "معلومات الاتصال",
        companyAddress: "العراق-أربيل , القرية الايطالية الاولى ,فيلا 248",
        mobile: "الموبايل",
        email: "الايميل",
        openingHours: 'مواعيد الدوام',
        openingHoursDays: "السبت - الخميس",
        openingHoursHm: "10:00 ص - 6:00 م",
        dayOff: "يوم العطلة",
        friday: "الجمعة",
        company: 'الشركة',
        position: "الوظيفة",
        empl: "موظف مبيعات",
        languages: "اللغات",
        compName: "جولدن ماونتنز",
        projects: "المشاريع",
        project: "مشروع",
        totalFlats: "عدد الشقق",
        buildingFloors: "عدد الطوابق",
        totalBuilding: "عدد الابنية",
        parkingLots: "عدد المواقف",
        avalibaleIn: "متاحة في",
        constructions: 'بناء',
        interiorDesigning: 'تصاميم داخلية',
        ivestment: 'استثمار',
        exteriorDesign: 'تصاميم خارجية',
        whatWeDoDesc: '\n' +
            'تعمل شركة  جولدن ماونتنز على تشكيل سوق العقارات الفاخرة في أربيل منذ عام 2019 ، حيث تقدم عقارات سكنية وتجارية وترفيهية مميزة في جميع أنحاء المنطقة\n' +
            'نقوم الان بالعمل على مشروعين سكنيين في اربيل الاول جولدن ماونتنز تاورز والثاني جولدن لاين,\n' +
            'هدف الشركة الرئيسي هو توفير عدد كبير من الشقق السكنية بأسعار مناسبة وأقساط مريحة. مما يسمح لذوي الدخل المحدود بامتلاك الوحدات السكنية بسهولة.',
        whatWeDoTitle: 'ماذا نفعل',
        ourProjects: 'مشاريعنا',
        forInvestment: "للإستثمار",
        workingHours: 'نعمل 7 أيام في الأسبوع ، كل يوم باستثناء أيام العطل الكبرى. اتصال\n' +
            '                             إذا كان لديك حالة طوارئ ، من خلال الخط الساخن ونموذج الاتصال.',
        aboutUsFooter: 'حيث نتخصص في تقديم الإنشاءات عالية الجودة\n' +
            '                                 خدمات للعملاء في مختلف الصناعات. تأسست شركتنا على مبادئ\n' +
            '                                 النزاهة والابتكار والتميز ، ونحن نقدم باستمرار التميز\n' +
            '                                 النتائج لعملائنا منذ بدايتنا.',
        whyChooseusFooter:'لدينا سنوات من الخبرة في صناعة البناء وعملنا في مجموعة متنوعة من المشاريع. يعرف فريقنا المتمرس كيفية إنجاز المهمة بكفاءة وفعالية. نحن متخصصون في مختلف مجالات البناء ولدينا فريق من الخبراء المهرة في مجالات تخصصهم. سواء كان إنشاءات تجارية أو سكنية ، لدينا الخبرة لتلبية احتياجاتك'


    }

}


